.shipments {
	line-height: 20px;
	&-filter {
		border-radius: 6px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 12px 24px;
		box-shadow: 0px 2px 14px #e6e6e6;
		background-color: white;
		.ant-checkbox + span {
			font-size: app-font-sizes(reg);
		}
		.ant-input-affix-wrapper {
			border-radius: 8px;
		}
		.ant-select:not(.ant-select-customize-input) .ant-select-selector {
			border-radius: 8px;
			height: 38px;
		}
		&__title {
			gap: 8px;
			flex-wrap: nowrap;
			& .title {
				& > h3 {
					font-size: app-font-sizes(md);
					white-space: nowrap;
					margin: 0;
				}
				flex-basis: fit-content;
			}

			& .divider {
				& .ant-divider {
					border-top: 1px solid #ebebeb;
				}
				flex-basis: 100%;
			}
		}
		&__statuses {
			display: flex;
			flex-wrap: wrap;
			&-label {
				font-size: app-font-sizes(reg);
				margin-right: 4px;
				margin-bottom: 7px;
			}
			& .status {
				border-radius: 20px;
				text-transform: capitalize;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #5a5a5a;
				font-size: app-font-sizes(sm);
				font-weight: 500;
				line-height: 20px;
				background-color: #f6f6f6;
				padding: 2px 10px;
				margin-right: 6px;
				margin-bottom: 7px;
				cursor: pointer;
				&.active,
				&:hover {
					background-color: #cd5e77;
					color: white;
				}
			}
		}
		&__form-input {
			&__text-field {
				color: Red;
			}
			&-input {
				// margin-bottom: 12px;
				// .ant-row {
				// 	margin: 0;
				// }
				// input:not([type='range']) {
				// 	border-radius: 4px;
				// }
				// input[type='range'] {
				// 	border-radius: 2px;
				// }
				// input {
				// 	width: 100%;
				// 	&::placeholder {
				// 		color: $color-app-gray1;
				// 	}
				// }
				// & .form-label {
				// 	font-size: app-font-sizes(reg);
				// 	white-space: nowrap;
				// 	line-height: 20px;
				// }
				// &__text-field{
				//     color:Red
				// }

				&--date {
					display: flex;
					align-items: center;
					gap: 10px;
					&.timestamp > .ant-form-item > .ant-form-item-label {
						flex-basis: 112px;
					}
					.ant-row {
						width: 100%;
						& .ant-picker {
							width: 100%;
							border-radius: 8px;
						}
					}
				}
				& .selection {
					flex-shrink: 0;
					& .ant-select {
						.ant-select-selection-item {
							line-height: 22px;
							display: flex;
							align-items: center;
							& .divider {
								border-left: 2px solid #707070;
							}
						}
					}
				}
			}
		}
		.actions {
			display: flex;
			height: 32px;
			&-btn {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				& > button {
					border-radius: 4px;
				}
				& .clear {
					margin-right: 12px;
					color: #707070;
					border: none;
					box-shadow: none;
					.anticon {
						transform: rotate(-69deg);
					}
					&:hover {
					}
				}
			}
		}
		.marketplaces {
			font-size: app-font-sizes(reg);
			& .ant-checkbox-group-item {
				margin-right: 30px;
			}
		}
	}
	&-data {
		.ant-pagination-item {
			font-family: 'Roboto', sans-serif;
			& > a {
				font-size: app-font-sizes(reg);
			}
			&-active {
				background-color: #cd5e77;
				& > a {
					color: white;
				}
			}
		}
		.ant-pagination-options .ant-select-selection-item {
			font-size: app-font-sizes(reg);
			color: #707070;
		}
		.ant-table-cell {
			vertical-align: middle;
			border-bottom: 12px solid #fff7f7;
			padding: 20px;
		}
		&__info {
			&-text {
				& .code {
					margin: 0 !important;
					font-weight: 500;
					white-space: nowrap;
					color: CD5E77;
					font-size: app-font-sizes(reg);
					line-height: 22px;
				}
				& .divider {
					width: 1px;
					height: 16px;
					background-color: #c7c7c7;
				}
			}
		}

		&__provider {
			line-height: 20px;
			flex-wrap: nowrap;
			& .logo {
				flex-shrink: none;
			}
			& .text {
				white-space: nowrap;
			}
		}

		&__status {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			font-size: app-font-sizes(reg);
			line-height: 20px;
			& > i {
				color: #cd5e77;
			}
			& > span {
				padding: 1px 10px;
				border-radius: 25px;
				color: white;
				white-space: nowrap;
			}

			&-button {
				margin-bottom: -24px;

				> span {
					text-decoration: underline;
				}
			}
		}
		.ant-pagination-total-text {
			margin-right: auto;
		}
	}
	// &_list {
	// 	margin-left: 0 !important;
	// 	margin-right: 0 !important;
	// 	// background-color: #cd5e77;
	// }
	&_items {
		background-color: white;
		border-radius: 6px;
		&.ant-list {
			& .ant-list-header {
				border-bottom: none;
				padding: 4px 12px 16px 12px;
				padding-left: 0;
			}
			& .ant-list-item {
				align-items: center;
				border-radius: 6px;
				border-bottom: none;
				flex-basis: 85%;
				margin-bottom: 12px;

				& .shipment-status {
					flex-grow: 0;
				}
				&:hover {
					background: #fff7f7;
				}
				padding: 12px 16px;
				& .ant-list-item-meta-title {
					font-size: 14px;
					margin-bottom: 0;
					line-height: 20px;
					font-weight: 400;
					& > div {
						height: 100%;
					}

					& .ant-divider {
						border-left: 1px solid #c7c7c7;
					}
				}

				& .ant-list-item-meta {
					align-items: center;
					&-content {
						margin-top: 5px;
					}
				}
			}
		}
	}
}
.header-list {
	margin-left: 16px;
}

.ant-list-split .ant-list-header {
	border: none !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
	background-color: #fff7f7;
}

// .ant-table-tbody > tr > td {
// 	border-bottom: none !important;
// }

.shipments-filter__form {
	position: relative;

	.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
		// border-color: $secondary-color !important;
		border-right-width: 1px;
		z-index: 1;
	}

	.ant-input-affix-wrapper:focus,
	.ant-input-affix-wrapper-focused {
		// border-color: $secondary-color !important;
		box-shadow: 0 0 0 2px RGB(24 144 255 / 20%);
		border-right-width: 1px;
		outline: 0;
	}

	.ant-input-affix-wrapper,
	textarea {
		border-radius: 6px !important;
	}

	.form-label {
		position: absolute;
		top: 50%;
		left: 30px;
		font-size: 12px;
		transform: translateY(-50%);
		color: gray;
		user-select: none;
		pointer-events: none;
		transition: 0.25s ease;
		z-index: 10;
	}

	.form-input {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.form-input:focus {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.form-input:not(:placeholder-shown) {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	// .form-input:not(:placeholder-shown) + .form-label,
	.form-input:focus + .form-label,
	.ant-input-affix-wrapper-focused + .form-label,
	.input-has-value + .form-label {
		font-size: 12px;
		font-weight: 400;
		top: 0px;
		background: #ffff;
		// padding: 0px 4px;
	}

	.input-has-value .ant-input-suffix {
		margin-top: -2px;
	}
}

.ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow.ant-select-show-search {
	line-height: 3 !important;
}

@media screen and (max-width: 1024px) {
	.headerList-select-view {
		display: none;
	}
	.end-row {
		margin-top: 30px;
		margin-bottom: 30px;
		border-top: 1px solid #e5e5e5;
	}

	.shipments_items.ant-list .ant-list-item {
		padding: 0;
	}
	.header-list {
		margin: 0;
	}
}

@media screen and (max-width: 767px) {
	.shipments_items {
		background-color: #e5e5e5;
	}
	.bg-white {
		background-color: #e5e5e5 !important;
	}
	.ant-layout {
		background-color: #e5e5e5;
	}

	.row-dark {
		background-color: #ffffff;
	}

	.row-light {
		background-color: #ffffff;
	}

	.shipments_items.ant-list .ant-list-item .ant-list-item-meta {
		padding: 16px;
	}
	.header-list {
		margin: 0;
	}
	.shipments_items {
		padding: 0 !important;
	}
	.shipments_items.ant-list .ant-list-item .ant-list-item-meta-content {
		margin: 0;
	}
	.clean-filter{
		display: none !important;
	}
}


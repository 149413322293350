// .a-table {
// 	&--responsive {
// 		width: 100%;
// 		overflow: auto;
// 	}
// 	&--font-small {
// 		table tbody {
// 			font-size: 12px;
// 		}
// 	}
// 	table {
// 		font-size: 12px;

// 		@media only screen and (min-width: 1200px) {
// 			font-size: 12px;
// 		}

// 		@media only screen and (min-width: 1400px) {
// 			font-size: 12px;
// 		}
// 	}
// }

// .main-table {
// 	& .ant-table-thead > tr > th {
// 		font-size: 14px;
// 		line-height: 22px;
// 		padding: 5px 12px;
// 		background-color: $table-header-bg;
// 		border-bottom: none;
// 		color: $color-app-base;
// 		background-color: $bg-color-gray-tab;
// 	}

// 	& .ant-table-title {
// 		padding: 0 !important;
// 		padding-bottom: 12px !important;
// 	}

// 	& .ant-table-tbody > tr > td {
// 		padding: 8px 12px;
// 		vertical-align: top;
// 		color: $color-app-base;
// 		& .ant-typography {
// 			color: $color-app-base;
// 			&-edit-content {
// 				left: 0;
// 			}
// 		}
// 	}

// 	&_middle {
// 		& .ant-table-tbody > tr > td {
// 			vertical-align: middle;
// 		}
// 	}

// 	&_small {
// 		& .ant-table-thead > tr > th {
// 			font-size: 12px;
// 			line-height: 20px;
// 		}
// 	}

// 	&__border-head-none {
// 		& .ant-table-thead > tr > th::before {
// 			display: none;
// 		}
// 	}
// }

// .pagination-separate {
// 	display: flex;
// 	margin-bottom: 12px;
// 	.ant-pagination-item {
// 		font-family: 'Roboto', sans-serif;
// 		& > a {
// 			font-size: app-font-sizes(reg);
// 		}
// 		&-active {
// 			background-color: $color-app-violet;
// 			& > a {
// 				color: white;
// 			}
// 		}
// 	}
// 	.ant-pagination-options .ant-select-selection-item {
// 		font-size: app-font-sizes(reg);
// 		color: #707070;
// 	}
// 	.ant-pagination-total-text {
// 		margin-right: auto;
// 		padding-left: 12px;
// 		padding-right: 12px;
// 	}
// }

// // @media screen and (max-width: 1280px) {
// // 	.main-tabe {
// // 		& .ant-table-content {
// // 			overflow: scroll !important;
// // 		}
// // 	}
// // }

// .ant-table {
// 	display: block;
// 	width: 100%;
// 	overflow-x: auto;
// 	-webkit-overflow-scrolling: touch;
// 	-ms-overflow-style: -ms-autohiding-scrollbar;
// }

// .main-table {
// 	z-index: 0;
// 	&__nested-pink {
// 		.ant-table.ant-table-empty table tbody tr.ant-table-placeholder:hover > td {
// 			background-color: #fff6e5 !important;
// 		}
// 	}
// 	&-orange-hovered {
// 		.ant-table {
// 			.ant-table-tbody > tr.ant-table-row:hover > td {
// 				background: $bg-color-pink2;
// 			}
// 		}
// 	}
// 	&.selected-table {
// 		tr {
// 			&.active-row {
// 				& > td {
// 					background-color: $bg-color-orange4 !important;
// 					border: none !important;
// 				}
// 				&::hover {
// 					& > td {
// 						// background-color: $bg-color-orange4 !important;
// 						background-color: #ffeece !important;
// 					}
// 				}
// 			}
// 		}
// 	}
// 	.ant-table {
// 		table > tbody > tr {
// 			& > td {
// 				&.ant-table-cell {
// 					vertical-align: middle;
// 					border-bottom: 1px solid #ebebeb;
// 					padding: 12px;
// 					white-space: nowrap;
// 				}
// 			}
// 			&::hover {
// 				background-color: #fafafa;
// 			}
// 		}
// 		&.ant-table-empty {
// 			table tbody tr {
// 				&:hover {
// 					& > td {
// 						background-color: white !important;
// 					}
// 				}
// 			}
// 		}
// 		& .ant-table-thead {
// 			tr > th {
// 				&.ant-table-cell {
// 					background-color: $bg-color-gray-tab !important;
// 					padding: 6px 12px;
// 					border-bottom: none;
// 					color: #707070 !important;
// 					white-space: nowrap;
// 					&::before {
// 						width: 0 !important;
// 					}
// 					&.ant-table-row-expand-icon-cell {
// 						padding: 0 !important;
// 						width: 0 !important;
// 						padding-left: 12px;
// 					}
// 				}
// 			}
// 		}

// 		& .ant-table-title {
// 			padding-top: 0;
// 		}
// 		table {
// 			tbody {
// 				tr {
// 					&::hover {
// 						background: red !important;
// 						td {
// 							&.ant-table-cell {
// 								background: red !important;
// 							}
// 						}
// 					}
// 					&.active-row {
// 						background-color: $bg-color-pink1;

// 						&::hover {
// 							& > td {
// 								background-color: $bg-color-pink1;
// 								border: none !important;
// 							}
// 						}
// 					}
// 					&.ant-table-expanded-row {
// 						& > td {
// 							background-color: $bg-color-pink2;
// 							border: none !important;
// 						}
// 					}
// 					td {
// 						&.ant-table-cell {
// 							vertical-align: middle;
// 							border-bottom: 1px solid #f5f5f5;
// 							padding: 12px;
// 							&.ant-table-row-expand-icon-cell {
// 								// width: 0 !important;
// 								// padding: 0 !important;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// 	&.nested-px {
// 		&-36 {
// 			.ant-table-expanded-row > td {
// 				padding-left: 36px !important;
// 			}
// 		}
// 		&-48 {
// 			.ant-table-expanded-row > td {
// 				padding-left: 72px !important;
// 			}
// 		}
// 		&-74 {
// 			.ant-table-expanded-row > td {
// 				padding-left: 112px !important;
// 			}
// 		}
// 	}
// 	&__nested {
// 		&-pink {
// 			.ant-table .ant-table-thead tr > th.ant-table-cell {
// 				background-color: #fff6e5 !important;
// 			}
// 		}
// 		& .ant-table {
// 			background-color: #fff6e5;
// 			& .ant-table-row:not(:last-child) {
// 				border-radius: 20px;
// 				& .ant-table-cell {
// 					border-bottom: 1px dashed #d2c5c5 !important;
// 				}
// 			}
// 		}
// 		table {
// 			width: auto;
// 		}
// 	}
// }
// .table-expandable {
// 	& .ant-table table tbody tr td.ant-table-cell.ant-table-row-expand-icon-cell {
// 		padding-left: 4px;
// 		padding-right: 4px;
// 	}

// 	&.package-list {
// 		& .ant-table table tbody tr td.ant-table-cell.ant-table-row-expand-icon-cell {
// 			// padding-left: 0 !important;
// 			// padding-right: 0 !important;
// 		}
// 		.ant-table.ant-table-small .ant-table-thead > tr {
// 			& > th {
// 				font-family: 'Roboto', sans-serif;
// 				font-size: 14px;
// 				line-height: 22px;
// 				color: #1a1a1a;
// 				font-weight: 500;
// 				&:first-of-type {
// 					padding-left: 0 !important;
// 					padding-right: 0 !important;
// 				}
// 				&:nth-child(2) {
// 					padding-left: 0 !important;
// 				}
// 			}
// 		}
// 		.ant-table.ant-table-small .ant-table-tbody > tr {
// 			& > td:nth-child(2) {
// 				padding-left: 0 !important;
// 			}
// 		}
// 	}
// 	.ant-table-expanded-row {
// 		& > td {
// 			padding: 0px auto !important;
// 			& .ant-table {
// 				margin: 0 !important;
// 				.ant-table-thead > tr > th.ant-table-cell {
// 					background-color: #fff6e5;
// 					&::before {
// 						width: 0;
// 					}
// 				}
// 				td {
// 					padding: 6px 12px !important;
// 					background-color: $bg-color-pink2;
// 					&::hover {
// 						background-color: unset;
// 					}
// 					border-bottom: none;
// 				}
// 			}
// 		}
// 	}

// 	&-bordered {
// 		.ant-table-expanded-row {
// 			& > td {
// 				& .ant-table {
// 					& .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody {
// 						& > tr:last-child > td {
// 							border-bottom: none !important;
// 						}
// 						& > tr > td:not(:last-child) {
// 							border-right: none !important;
// 							border-bottom: 1px solid #ececec !important;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// .ant-table-selection-col,
// .ant-table-expand-icon-col {
// 	// width: 16px;
// }

.table-improved {
	& .ant-table-content {
		& .ant-table-thead {
			& > tr > th {
				padding-top: 6px;
				padding-bottom: 6px;
				font-size: 12px;
				line-height: 20px;
				white-space: nowrap;
				font-weight: 500;
				border-bottom: none;
				background-color: #f4f4f4;
				&::before {
					width: 0 !important;
				}
				&:first-of-type {
					border-top-left-radius: 6px !important;
				}

				&:last-of-type {
					border-top-right-radius: 6px !important;
				}
			}
		}

		& .ant-table-tbody {
			&::before {
				content: '-';
				display: block;
				line-height: 1em;
				color: transparent;
			}
			& > tr > td {
				// border-radius: 8px;
				vertical-align: middle;
				border-bottom: none;
				padding-bottom: 0;

				&:first-child {
					// border-top-left-radius: 6px;
					// border-bottom-left-radius: 6px;
				}
				&:last-child {
					// border-top-right-radius: 6px;
					// border-bottom-right-radius: 6px;
				}
			}
			& .ant-table-expanded-row {
				& > td {
					padding: 17px 50px;
					background-color: $bg-color-pink6;
					border-bottom: 0 !important;
					&:last-child {
						border-top-left-radius: 0;
						border-top-right-radius: 0;
					}
				}
				& .shipment-expand-row {
				}
			}
			& .active-row {
				border-radius: 8px !important;
				& > td {
					border-bottom: 0 !important;
				}
			}
		}

		.ant-table-tbody > tr.ant-table-row:not(.data-row_extended):hover > td,
		.ant-table-tbody > tr.ant-table-row:not(.data-row_extended):hover + tr > td {
			background-color: #fff7f7 !important;
			& > i {
				color: $txt-color-red;
			}
		}
		tr.ant-table-row.data-row_extended:hover > td {
			background-color: unset;
		}
	}

	& .ant-table {
		border-radius: 6px;
		padding: 0 16px 16px 16px;
		& .ant-table-title {
			padding-left: 0;
			padding-right: 0;
		}
		& .ant-table-content {
			// padding: 0 16px;
		}
	}
}

// .ant-table-tbody > tr > td {
// 	padding: 8px 16px;
// }

